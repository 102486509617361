import React, { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import useSWR, { mutate } from 'swr';
import * as Yup from 'yup';
import MilStarCardSection from 'components/commercetools-ui/account/details/sections/militaryStarCardSection';
import Loader from 'components/commercetools-ui/loader/Loader';
import {
  PHYSICAL_GIFT_CARDS,
  DEFAULT_MILSTAR_PLAN_NUMBER,
  MILITARY_ITEMS_PLAN_NUMBER,
  DONATION_ITEMS,
  VIA_EMAIL_PRODUCTS,
  EGIFT_CUSTOM_PRODUCTS,
  AMOUNT_ELIGIBLE_FOR_MILSTAR_PYW_INSTALLMENTS,
  GA_CONTINUE_TO_PAYMENT,
  GA_ORDER_REVIEW,
} from 'helpers/constants/aafes';
import { stateOptions } from 'helpers/constants/stateList';
import useGATrackCheckoutAction from 'helpers/hooks/gaEvents/useGATrackCheckoutAction';
import useGATrackUserAction from 'helpers/hooks/gaEvents/useGATrackUserAction';
import { useFormat } from 'helpers/hooks/useFormat';
import { blackBoxValueScript } from 'helpers/utils/blackBox';
import { splitFullName } from 'helpers/utils/fieldValidators';
import { getGuestShippingAddresses } from 'helpers/utils/getGuestShippingAddresses';
import { getSeparatedDeliverySections } from 'helpers/utils/getSeparatedDeliverySections';
import IdentifyCardType from 'helpers/utils/IdentifyCardType';
import { sendToMonetate } from 'helpers/utils/monetateHelper';
import { fetchApiHub, revalidateOptions, useAccount, useCart, useCustomMessages } from 'frontastic';
import { removePayment, getCartInventory, validateInventory, getCartItems } from 'frontastic/actions/cart';
import CheckShippingPickupIconActive from '../../../icons/checkShippingPickupActive';
import CheckShippingPickupIconDisabled from '../../../icons/checkShippingPickupDisabled';
import EditPencil from '../../../icons/editPencil';
import giftImg from '../../../images/giftCard.png';
import OrderSummary from '../cart/orderSummary';
const OrderReviewSection = dynamic(() => import('./orderReviewSection'));
const ShippingSection = dynamic(() => import('./shippingSection'));
const PaymentSection = dynamic(() => import('./paymentSection'));
const AddNewCard = dynamic(() => import('../Modals/addNewCard'));
const EditBillingAddressModal = dynamic(() => import('../Modals/editBillingAddressModal'));
const PayYourWay = dynamic(() => import('../Modals/payYourWay'));
const PrivayAct = dynamic(() => import('../Modals/privacyActModal'));
const SiteTermModal = dynamic(() => import('../Modals/siteTermsConditions'));

function Checkout({ cartList, onSubmit, giftCard }) {
  const router = useRouter();
  const { account, loggedIn } = useAccount();
  const { formatMessage: formatCheckoutMessage } = useFormat({ name: 'checkout' });
  const { formatMessage: formatCommonMessage } = useFormat({ name: 'common' });
  const [activeShipment, setActiveShipment] = useState(true);
  const [activePayment, setActivePayment] = useState(false);
  const [activeOrderReview, setActiveOrderReview] = useState(false);
  const [isEditBillingModalOpen, setIsEditBillingModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [privacyAct, setPrivacyAct] = useState(false);
  const [siteterms, setSiteTerms] = useState(false);
  const {
    checkout,
    addCreditCardForPayment,
    guestCheckout,
    reCalculatePayments,
    fetchDefaultPayment,
    studioConfig,
    getOrderByCartId,
  } = useCart();
  const { getNewCreditCards } = useAccount();
  const [showLoader, setShowLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [giftCardsApplied, setGiftCardsApplied] = useState<any>({});
  const [getCardType, setGetCardType] = useState(null);
  const [paymentSectionError, setPaymentSectionError] = useState('');
  const [billingAddressError, setBillingAddressError] = useState('');
  const [shippingSectionError, setShippingSectionError] = useState('');
  const [inventoryError, setInventoryError] = useState({});
  const [unexpectedError, setUnexpectedError] = useState('');
  const [reviewEmail, setReviewEmail] = useState(account?.email ? account.email : '');
  const [reviewPhone, setReviewPhone] = useState('');
  const [reviewOptinComm, setReviewOptinComm] = useState(true);
  const [showCardSelector, setShowCardSelector] = useState(false);
  const [selectedCardDetails, setSelectedCardDetails] = useState(null);
  const [isReviewButtonDisabled, setIsReviewButtonDisabled] = useState<boolean>(true);
  const [payYourWayOptions, setPayYourWayOptions] = useState({});
  const [cardDisplayNames, setCardDisplayNames] = useState({});
  const [isPlaceYourOrder, setIsPlaceYourOrder] = useState(true);

  const [nameOnCard, setNameOnCard] = useState('');
  const [creditCardNumber, setCreditCardNumber] = useState('');
  const [expirationDate, setExpirationDate] = useState('');
  const [securityCode, setSecurityCode] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [streetName, setStreetName] = useState('');
  const [streetNumber, setStreetNumber] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [phone, setPhone] = useState('');
  const [checked, setChecked] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [isBillingSame, setIsBillingSame] = useState(false);
  const [isShowGuestPaymentSummary, setIsShowGuestPaymentSummary] = useState<boolean>(false);
  const [detectedCardType, setDetectedCardType] = useState(''); // Credit Card type
  const [creditCardActive, setCreditCardActive] = useState(false);
  const [dropDownActive, setDropDownActive] = useState(true);
  const [monetateConfig, setMonetateConfig] = useState(null);
  const [monetateSent, setMonetateSent] = useState(false);
  const [installmentKeys, setInstallmentKeys] = useState([]);
  const { setCartProductDetailEventSent, studioConfig: projectConfiguration } = useCart();

  const [blackBoxValue, setBlackBoxValue] = useState<HTMLInputElement | null>(null);
  const [blackBox, setBlackBox] = useState('');
  const [getAllCustomMessages, setGetCustomMessages] = useState(null);
  const [isAllMilitaryItems, setIsAllMilitaryItems] = useState(false);
  const { trackUserAction } = useGATrackUserAction();
  const [openPayYourWayModal, setOpenPayYourWayModal] = useState(false);
  const [isAnyProductSubscribed, setIsAnyProductSubscribed] = useState(false);
  const [genericError, setGenericError] = useState('');
  const [isCheckoutErrorLoading, setIsCheckoutErrorLoading] = useState(false);
  const [milStarCardError, setMilStarCardError] = useState(false);
  const [cvv, setCvv] = useState('');
  const [blockedCards, setBlockedCards] = useState([]);
  const [storeClosed, setStoreClosed] = useState(false);

  const { data } = useSWR(
    `/action/customObjects/getCustomMessages?messages=deliverEvery,receiveAGiftCard,transhipMsg,checkoutFailed,getMilStarCardErrorMsg`,
    fetchApiHub,
    revalidateOptions,
  );

  useEffect(() => {
    setGetCustomMessages(data);
  }, [data]);

  useEffect(() => {
    const defaultPayment = async () => {
      try {
        setLoading(true);
        if (loggedIn) {
          await fetchDefaultPayment();
          await reCalculatePayments();
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };

    defaultPayment();
  }, [loggedIn]);

  const paymentInfo = {
    creditCardNumber: creditCardNumber,
    firstName: firstName,
    lastName: lastName,
    streetName: streetName,
    streetNumber: streetNumber,
    city: city,
    state: state,
    postalCode: postalCode,
  };

  const editShippingPickup = () => {
    setActiveShipment(true);
    setIsPlaceYourOrder(true);
  };

  const editPayment = () => {
    setActivePayment(true);
    setIsPlaceYourOrder(true);
  };
  let cartFetchCount = 0;
  let lastOrderCardId: string;

  function checkBlockedCards() {
    mutate('/action/account/getAccount')
      .then((data) => {
        const currentTime = new Date();

        const blockedTokens = [];

        data.account.invalidCvvEntries.forEach((entry) => {
          const { lockoutCount, lockoutDate, token } = entry;

          if (lockoutCount >= projectConfiguration?.maxInvalidCvvAttempts) {
            const lockoutTime = new Date(lockoutDate);
            const currentTimeMs = currentTime.getTime();
            const lockoutTimeMs = lockoutTime.getTime();

            const timeDifferenceMs = currentTimeMs - lockoutTimeMs;
            const timeDifferenceMinutes = timeDifferenceMs / (1000 * 60);

            if (timeDifferenceMinutes < projectConfiguration?.lockDurationMinsCvvInvalidAttempts) {
              blockedTokens.push(token);
            }
          }
        });

        setBlockedCards(blockedTokens);
      })
      .catch((error) => {
        console.error('Error during mutate:', error);
      });
  }
  useEffect(() => {
    if (projectConfiguration?.maxInvalidCvvAttempts) {
      checkBlockedCards();
    }
  }, [projectConfiguration?.maxInvalidCvvAttempts]);

  useEffect(() => {
    function checkCloseStore() {
      getSeparatedDeliverySections(cartList?.displayLineItems)?.map((item, index) => {
        if (item?.channel?.id && item?.channel.isClickToBrick === false) {
          setStoreClosed(true);
        }
      });
    }
    checkCloseStore();
  }, [cartList]);
  const placeOrder = () => {
    let checkout599Error;
    setShowLoader(true);
    setUnexpectedError('');
    validateInventory()
      .then((response: any) => {
        lastOrderCardId = response?.cartId;
        if (response?.errorCode) {
          handleCheckoutErrors(response);
          setShowLoader(false);
        } else {
          loggedIn
            ? checkout({
                orderEmail: reviewEmail,
                orderPhone: reviewPhone,
                optIn: reviewOptinComm,
                ioBlackBox: blackBox,
                cvv,
              })
                .then((res) => {
                  setIsCheckoutErrorLoading(true);
                  const checkout599Error = res?.errorCode === 599;
                  if (res?.errorCode === 500) {
                    if (res?.isInvalidCVV) {
                      checkBlockedCards();
                    }

                    handleCheckoutErrors(res);
                    setShowLoader(false);
                  } else if (res?.orderId) {
                    router.push('/order-confirmation?orderId=' + res.cartId);
                  } else if (checkout599Error) {
                    const intervalId = setInterval(() => {
                      if (cartFetchCount < projectConfiguration?.cartFetchAttemptCounter) {
                        const fetchCartList = async () => {
                          setIsCheckoutErrorLoading(true);
                          try {
                            const res = await getCartItems();
                            const lineItems = [...res?.lineItems, ...res?.customLineItems];
                            if (res && lineItems?.length === 0) {
                              try {
                                const res = await getOrderByCartId(lastOrderCardId);
                                if (res && res?.cartId) {
                                  router.push('/order-confirmation?orderId=' + res?.cartId);
                                  setIsCheckoutErrorLoading(false);
                                }
                                if (res && res?.errorCode === 500) {
                                  setGenericError(getAllCustomMessages?.checkoutFailed);
                                  setIsCheckoutErrorLoading(false);
                                }
                                clearInterval(intervalId);
                                return;
                              } catch (err) {
                                console.log(err);
                              }
                            } else {
                              setGenericError(getAllCustomMessages?.checkoutFailed);
                            }
                          } catch (error) {
                            console.error('Error fetching cartList:', error);
                          }
                        };
                        fetchCartList();
                        cartFetchCount++;
                      } else {
                        setIsCheckoutErrorLoading(false);
                        clearInterval(intervalId);
                      }
                    }, projectConfiguration?.checkoutGetCartExpirationSecs);
                  }
                })
                .catch((err) => console.log(err))
                .finally(() => {
                  setShowLoader(false);
                  if (!checkout599Error) {
                    setIsCheckoutErrorLoading(false);
                  }
                })
            : PlaceGuestOrder();
        }
      })
      .catch((err) => console.log(err))
      .finally();
  };

  useEffect(() => {
    if (loggedIn) {
      if (activeOrderReview) {
        if (reviewEmail === '' || emailError !== '') setIsPlaceYourOrder(true);
        else if (reviewOptinComm && (reviewPhone === '' || phoneError !== '')) setIsPlaceYourOrder(true);
        else setIsPlaceYourOrder(false);
      } else setIsPlaceYourOrder(true);
    } else {
      if (reviewEmail === '' || emailError !== '') setIsPlaceYourOrder(true);
      else setIsPlaceYourOrder(false);
    }
  }, [reviewOptinComm, reviewPhone, reviewEmail, isPlaceYourOrder, emailError, phoneError]);

  const PlaceGuestOrder = () => {
    setPaymentSectionError('');
    const [expMon, expYear] = expirationDate?.split('/');
    guestCheckout({
      cardholderName: nameOnCard,
      pan: creditCardNumber,
      expiryDate: expYear?.toString() + expMon?.toString(),
      cardSecurityCode: securityCode,
      cardType: IdentifyCardType(creditCardNumber, true),
      billingAddress: {
        firstName: firstName,
        lastName: lastName,
        streetName: streetName,
        streetNumber: streetNumber,
        city: city,
        state: state,
        postalCode: postalCode,
        phone: phone,
        country: 'US',
      },
      orderEmail: reviewEmail,
      orderPhone: reviewPhone as unknown as number,
    })
      .then((res) => {
        if (res?.errorCode) {
          setPaymentSectionError(res.body);
        }
        if (res?.orderId) {
          router.push('/scs/order-confirmation?orderId=' + res.cartId);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setShowLoader(false));
  };

  const handleCheckoutErrors = (response) => {
    const apiError = response.body;
    switch (response?.checkout_state ? response.checkout_state : 'unexpected') {
      case 'payment':
      case 'payment-fraud':
      case 'payment-stargate':
      case 'payment-unallocatedAmount':
      case 'payment-cancel':
        setPaymentSectionError(apiError);
        setActivePayment(true);
        setActiveShipment(true);
        setActiveOrderReview(false);
        setIsReviewButtonDisabled(true);
        setIsPlaceYourOrder(true);
        break;
      case 'shipping-missing-address':
      case 'shipping':
        setShippingSectionError(apiError);
        setActiveShipment(true);
        setActivePayment(false);
        setActiveOrderReview(false);
        setIsPlaceYourOrder(true);
        break;
      case 'billing':
        setBillingAddressError(apiError);
        setActivePayment(true);
        setActiveShipment(true);
        setActiveOrderReview(false);
        setIsPlaceYourOrder(true);
        break;
      case 'inventory':
      case 'promise-inventory':
        setInventoryError(apiError);
        setActiveShipment(true);
        setActivePayment(false);
        setActiveOrderReview(false);
        setIsPlaceYourOrder(true);
        break;
      case 'unexpected':
        setUnexpectedError(apiError);
        setActiveOrderReview(true);
        setActivePayment(true);
        setActiveShipment(true);
        setIsPlaceYourOrder(true);
        break;
      case 'billing-phonenumber':
        setBillingAddressError(apiError);
        setActivePayment(true);
        setActiveShipment(true);
        setActiveOrderReview(false);
        setIsPlaceYourOrder(true);
        break;
    }
  };

  const getformattedAddress = (address) => {
    let formattedAddress = '';
    if (address.additionalAddressInfo) {
      formattedAddress = formattedAddress + address.additionalAddressInfo + ' - ';
    }
    if (address.streetName) {
      formattedAddress = formattedAddress + address.streetName + ', ';
    }
    if (address.streetNumber) {
      formattedAddress = formattedAddress + address.streetNumber + ', ';
    }
    if (address.city) {
      formattedAddress = formattedAddress + address.city + ', ';
    }
    if (address.state) {
      formattedAddress = formattedAddress + address.state + ' ';
    }
    if (address.postalCode) {
      formattedAddress = formattedAddress + address.postalCode;
    }
    return formattedAddress;
  };

  const [allCards, setAllCards] = useState([]);
  const isCardExpired = allCards.filter((card) => card.isExpired === true);
  const expiredCardTokens = isCardExpired?.map((card) => card?.token);
  const [showUseCardOption, setShowUseCardOption] = useState(true);

  const newCreditCardAddedToAccount = async () => {
    const mergedCards = await getAllUserCreditCards();
    // check if new card was added by user
    const newCards = mergedCards.filter((card1) => !allCards.some((card2) => card1.token === card2.token));
    setAllCards(mergedCards);
    if (newCards.length == 1 && cartList?.remainingBalanceToPay?.centAmount > 0) {
      const newCard = newCards[0];
      await addCardForPayment(newCard);
      setShowLoader(false);
    }
  };

  const getAllUserCreditCards = async () => {
    setShowLoader(true);
    const cardRes = await getNewCreditCards();
    const newMilstarCards = cardRes?.response?.milstaraccount ?? [];
    const newBankCards = cardRes?.response?.bankcardaccount ?? [];
    const mergedCards = [...newMilstarCards, ...newBankCards];
    setShowLoader(false);
    return mergedCards;
  };
  function PYWPlanRadioCheckSelection(creditCardPayment, installmentKeys, cartList, isAllMilitaryItems) {
    if (creditCardPayment?.cardType?.startsWith('Milstar')) {
      const PYWPlanNumber = installmentKeys?.includes(cartList?.lineItems?.[0]?.promoPlan)
        ? cartList?.lineItems?.[0]?.promoPlan
        : isAllMilitaryItems
        ? MILITARY_ITEMS_PLAN_NUMBER
        : DEFAULT_MILSTAR_PLAN_NUMBER;
      setPayYourWaySelectedOption(PYWPlanNumber);
    }
  }
  useEffect(() => {
    const creditCardPayment = cartList?.payments?.find((payment) => payment?.paymentMethod === 'creditCard');
    if (creditCardPayment && creditCardPayment.token) {
      PYWPlanRadioCheckSelection(creditCardPayment, installmentKeys, cartList, isAllMilitaryItems);
    }
  }, []);
  useEffect(() => {
    if (cartList?.lineItems?.length === 0 && cartList?.customLineItems?.length === 0) {
      router.push('/');
    }
    const setCcData = async () => {
      let allCCs = loggedIn ? await getAllUserCreditCards() : [];
      let creditCardPayment = cartList?.payments?.find((payment) => payment.paymentMethod === 'creditCard');
      if (creditCardPayment && creditCardPayment.token) {
        const isPaymentPresentInAccount = allCCs.find((payment) => payment.token === creditCardPayment.token);
        if (!isPaymentPresentInAccount) {
          await removePayment(creditCardPayment.id);
          creditCardPayment = undefined;
        }
      } else if (creditCardPayment && creditCardPayment.id) {
        allCCs = [...allCCs, creditCardPayment];
      } else if (!creditCardPayment) {
        setShowCardSelector(true);
      }
      allCCs = allCCs.filter((cc) => cc.token || cc.id == creditCardPayment.id);
      setAllCards(allCCs);
    };
    setCcData();
  }, [loggedIn]);

  useEffect(() => {
    const creditCardPayment = cartList?.payments?.find((payment) => payment.paymentMethod === 'creditCard');
    if (!creditCardPayment && cartList?.unallocatedAmount?.centAmount > 0) {
      setShowCardSelector(true);
    }
  }, [cartList]);

  useEffect(() => {
    const setCardsAndPayments = async () => {
      const creditCardPayment = cartList?.payments?.find((payment) => payment.paymentMethod === 'creditCard');
      let newAllCards = allCards.filter((cc) => cc.token || cc.id == creditCardPayment?.id);
      setSelectedCardDetails(creditCardPayment);
      if (creditCardPayment) {
        setSelectedCardValue(creditCardPayment.token || creditCardPayment.id);
        const isDuplicate = newAllCards?.some((creditCard) => creditCard?.id === creditCardPayment?.id);
        if (!creditCardPayment?.token && !isDuplicate) {
          newAllCards = [...newAllCards, creditCardPayment];
        }
        setSelectedCardDetails(creditCardPayment);
        setShowCardSelector(false);
        setIsReviewButtonDisabled(false);
      }
      const isNoPaymentsInCartList = cartList?.payments?.length === 0;
      const isUnallocatedAmountGreaterThanZero = cartList?.unallocatedAmount?.centAmount > 0;
      if (!creditCardPayment && (isNoPaymentsInCartList || isUnallocatedAmountGreaterThanZero)) {
        setSelectedCardValue(null);
        setShowUseCardOption(true);
      } else {
        setShowUseCardOption(false);
      }
      setAllCards(newAllCards);
      const giftCardPayments = cartList?.payments?.filter((payment) => payment.paymentMethod === 'giftCard');
      if (giftCardPayments?.length) {
        let giftSum = 0;
        for (const payment of giftCardPayments) {
          giftSum += payment.amountPlanned.centAmount;
        }
        setGiftCardsApplied({
          centAmount: -giftSum,
          currencyCode: 'USD',
          fractionDigits: 2,
        });
      } else {
        setGiftCardsApplied({});
      }
      setShowLoader(false);
    };
    loggedIn && setCardsAndPayments();
  }, [cartList?.payments]);

  const [addrError, setAddrError] = useState(false);
  const [selectedCardValue, setSelectedCardValue] = useState(null);
  const [cardError, setCardError] = useState(false);
  const [addPaymentError, setAddPaymentError] = useState('');
  const [isCardChange, setIsCardChange] = useState(false);
  const [isPYWOptionCheck, setIsPYWOptionCheck] = useState(false);
  function dropDownChange() {
    setDropDownActive(false);
  }
  const isCardErrorMsg = expiredCardTokens?.includes(selectedCardDetails?.token) ?? false;

  function handleCardChange(event) {
    handleCreditCardClick();
    handleCreditCardSelection();
    setAddPaymentError('');
    setCardError(false);
    setCvv('');
    setSelectedCardValue(event.target.value);
    if (event.target.value === '') {
      const creditCardPayment = cartList?.payments?.find((payment) => payment.paymentMethod === 'creditCard');
      removePayment(creditCardPayment?.id);
    }
    setSelectedCardDetails(allCards.find((card) => card.token === event.target.value));
    addCardForPayment(event.target.value);
    setIsCardChange(true);
    if (isAllMilitaryItems) {
      setPayYourWaySelectedOption(MILITARY_ITEMS_PLAN_NUMBER);
    } else {
      setPayYourWaySelectedOption(DEFAULT_MILSTAR_PLAN_NUMBER);
    }
  }

  const paymentSectionValidation = async () => {
    setBillingAddressError('');
    setPaymentSectionError('');
    if (((cartList?.unallocatedAmount?.centAmount > 0 && loggedIn) || isAnyProductSubscribed) && !selectedCardValue) {
      setCardError(true);
      return;
    }
    if (
      cartList?.remainingBalanceToPay?.centAmount > 0 &&
      loggedIn &&
      !(Object.keys(cartList?.billingAddress ? cartList.billingAddress : {}).length > 0)
    ) {
      setAddrError(true);
      return;
    }
    if (detectedCardType === 'Invalid') {
      setMilStarCardError(true);
      return;
    }
    setShowLoader(true);
    setShowLoader(false);
    setActiveOrderReview(true);
    setIsPlaceYourOrder(false);
    setAddrError(false);
    setCardError(false);
    setActiveShipment(true);
    setActivePayment(true);
    setIsShowGuestPaymentSummary(true);
  };

  useEffect(() => {
    if (projectConfiguration?.payYourWayInstallments) {
      const installments = projectConfiguration?.payYourWayInstallments;
      const regexForKey = /"(\d+)":/g;
      const keys = [];
      let matchingKey;
      while ((matchingKey = regexForKey.exec(installments)) !== null) {
        keys.push(matchingKey[1]);
      }
      setInstallmentKeys(keys);
      const payYourWay = Function('installments', 'return (' + installments + ')')();
      setPayYourWayOptions(payYourWay);
    }
    if (projectConfiguration?.cardTypeDisplayName) {
      try {
        setCardDisplayNames(JSON.parse(projectConfiguration?.cardTypeDisplayName));
      } catch (error) {
        console.error('Errorparsing projectConfig for cardTypeDisplayName:', error);
      }
    }
  }, [projectConfiguration]);

  const [payYourWaySelectedOption, setPayYourWaySelectedOption] = useState(
    installmentKeys.includes(cartList?.lineItems?.[0]?.promoPlan)
      ? cartList?.lineItems?.[0]?.promoPlan
      : isAllMilitaryItems
      ? MILITARY_ITEMS_PLAN_NUMBER
      : DEFAULT_MILSTAR_PLAN_NUMBER,
  );

  const handleRadioChange = (event) => {
    setPayYourWaySelectedOption(event.target.value);
    setIsCardChange(false);
    setIsPYWOptionCheck(true);
  };

  const availableInstallmentsforMilstar = (minimumAmount) => {
    const availableInstallments = Object.entries(payYourWayOptions);
    const eligibleInstallments = [];
    for (const plan in availableInstallments) {
      if (availableInstallments[plan][1]['minAmount'] <= minimumAmount / 100) {
        eligibleInstallments.push(availableInstallments[plan][0]);
      }
    }
    eligibleInstallments.sort((a, b) => {
      return installmentKeys.indexOf(a) - installmentKeys.indexOf(b);
    });
    setInstallmentKeys(eligibleInstallments);
  };
  useEffect(() => {
    const amountToPayByMilstar = cartList?.remainingBalanceToPay?.centAmount;
    if (amountToPayByMilstar >= AMOUNT_ELIGIBLE_FOR_MILSTAR_PYW_INSTALLMENTS) {
      availableInstallmentsforMilstar(amountToPayByMilstar);
    }
  }, [activePayment, cartList]);

  const addCardForPayment = async (selectedCard) => {
    const existingCardPayment = cartList?.payments?.find((payment) => payment.paymentMethod === 'creditCard');
    let selectedCardDetails = null;
    if (selectedCard?.token) {
      selectedCardDetails = selectedCard;
    } else {
      selectedCardDetails = allCards.find((card) => card.token === selectedCard);
    }

    if (
      cartList?.unallocatedAmount?.centAmount > 0 ||
      selectedCard !== existingCardPayment?.token ||
      isPYWOptionCheck
    ) {
      setIsPYWOptionCheck(false);
      if (selectedCardDetails) {
        setShowLoader(true);
        let updatedPayload = null;
        if (selectedCardDetails.cardtype.startsWith('Milstar')) {
          updatedPayload = {
            creditCardToken: selectedCardDetails.token,
            paymentMethod: 'creditCard',
            cardType: selectedCardDetails.cardtype,
            expDate: selectedCardDetails.expirydate,
            planNumber: parseInt(payYourWaySelectedOption),
            planMonths: payYourWayOptions[payYourWaySelectedOption]?.label,
            planMinAmount: payYourWayOptions[payYourWaySelectedOption]?.minAmount,
            billingAddress: {
              addressId: selectedCardDetails.addressId,
              ...splitFullName(selectedCardDetails?.cardholdername),
              streetName: selectedCardDetails.billingaddress1,
              streetNumber: selectedCardDetails.billingaddress2,
              postalCode: selectedCardDetails.zipcode,
              city: selectedCardDetails.city,
              country: selectedCardDetails.country,
              state: selectedCardDetails.state,
              phone: selectedCardDetails.billingphone,
            },
          };
        } else {
          updatedPayload = {
            creditCardToken: selectedCardDetails.token,
            paymentMethod: 'creditCard',
            cardType: selectedCardDetails.cardtype,
            expDate: selectedCardDetails.expirydate,
            billingAddress: {
              addressId: selectedCardDetails.addressId,
              ...splitFullName(selectedCardDetails?.cardholdername),
              streetName: selectedCardDetails.billingaddress1,
              streetNumber: selectedCardDetails.billingaddress2,
              postalCode: selectedCardDetails.zipcode,
              city: selectedCardDetails.city,
              country: selectedCardDetails.country,
              state: selectedCardDetails.state,
              phone: selectedCardDetails.billingphone,
            },
          };
        }

        if (updatedPayload !== null) {
          addCreditCardForPayment(updatedPayload)
            .then((res) => {
              if (res?.errorCode) {
                setAddPaymentError(res.body);
              } else {
                setAddrError(false);
                setCardError(false);
              }
            })
            .catch((err) => console.log(err))
            .finally(() => setShowLoader(false));
        }
      }
    }
  };

  useEffect(() => {
    if (isPYWOptionCheck) {
      addCardForPayment(selectedCardValue);
    }
  }, [isPYWOptionCheck]);

  useEffect(() => {
    setReviewPhone(account?.profilePhone ? account.profilePhone : '');
    setReviewEmail(account?.email ? account.email : '');
  }, [account]);

  useEffect(() => {
    const defaultCard = allCards.find((data) => {
      return data?.preferredcard === 'Y';
    });
    if (defaultCard) {
      setShowUseCardOption(false);
    }
  }, []);

  const securityCodeValidation = (detectedCardType) => {
    return Yup.string()
      .required(formatCommonMessage({ id: 'securityCodeRequired', defaultMessage: 'Security Code is required.' }))
      .matches(
        /^\d*$/,
        formatCommonMessage({ id: 'enterValidSecurityCode', defaultMessage: 'Security code cannot exceed 4 digits.' }),
      )
      .test('noTripleZero', 'Please enter valid security code', (value) => {
        if (/^0{3,4}$/.test(value)) {
          return false;
        }
        return true;
      })
      .min(
        detectedCardType === 'Amex' ? 4 : 3,
        `Security code must have ${detectedCardType === 'Amex' ? '4' : '3'} digits`,
      )
      .max(
        detectedCardType === 'Amex' ? 4 : 3,
        `Security code must have ${detectedCardType === 'Amex' ? '4' : '3'} digits`,
      );
  };

  const shippingAddressFormSchema = Yup.object().shape({
    additionalAddressInfo: Yup.string(),
    firstName: Yup.string()
      .required(formatCommonMessage({ id: 'fNameRequired', defaultMessage: 'First name is required.' }))
      .matches(
        /^[A-Za-z]+$/,
        formatCommonMessage({ id: 'validFirstName', defaultMessage: 'Enter a valid first name.' }),
      ),
    lastName: Yup.string()
      .required(formatCommonMessage({ id: 'lNameRequired', defaultMessage: 'Last name is required.' }))
      .matches(/^[A-Za-z]+$/, formatCommonMessage({ id: 'validLastName', defaultMessage: 'Enter a valid last name.' })),
    nameOnCard: Yup.string()
      .required('Name On Card is required.')
      .test(
        'no-special-numeric',
        'Please try again. Enter the name exactly as it appears on your credit card.',
        (value) => {
          if (value && !/^[a-zA-Z\s]*$/.test(value)) {
            return false;
          }
          return true;
        },
      ),
    streetName: Yup.string()
      .required(formatCommonMessage({ id: 'address1Required', defaultMessage: 'Address line 1 is required.' }))
      .matches(
        /^[A-Za-z0-9\s\-]+$/,
        formatCommonMessage({ id: 'validStreetName', defaultMessage: 'Enter a valid street name.' }),
      ),
    streetNumber: Yup.string().matches(
      /^[A-Za-z0-9\s\-]*$/,
      formatCommonMessage({ id: 'validStreetName', defaultMessage: 'Enter a valid street name.' }),
    ),
    city: Yup.string()
      .required(formatCommonMessage({ id: 'cityRequired', defaultMessage: 'City is required.' }))
      .matches(/^[A-Za-z\s]+$/, formatCommonMessage({ id: 'validCity', defaultMessage: 'Enter a valid city name.' })),
    state: Yup.string()
      .required(formatCommonMessage({ id: 'stateRequired', defaultMessage: 'Please select state.' }))
      .matches(/^[A-Za-z\s]+$/, formatCommonMessage({ id: 'validState', defaultMessage: 'Enter a valid state name.' })),
    postalCode: Yup.string()
      .required(formatCommonMessage({ id: 'zipRequired', defaultMessage: 'Zip code is required.' }))
      .test(
        'testOnlyNum',
        formatCommonMessage({ id: 'enterValidZip', defaultMessage: 'Please enter a valid zip code' }),
        (val) => {
          val = val ? val : '';
          const regExp = new RegExp('^[\\d-]+$');
          return regExp.test(val);
        },
      )
      .max(10, formatCommonMessage({ id: 'zipShouldBe10', defaultMessage: 'Zip code should be 5-10 digits.' }))
      .min(5, formatCommonMessage({ id: 'zipShouldBe5', defaultMessage: 'Zip code should be 5 digits.' })),
    expirationDate: Yup.string()
      .required('Expiration Date is required.')
      .test('isFutureDate', 'Please enter a valid Expiration Date', (val) => {
        if (!/^\d{2}\/\d{2}$/.test(val)) return false;

        const month = parseInt(val.substr(0, 2), 10);
        const year = parseInt(val.substr(3, 2), 10);
        const today = new Date();
        const currentYear = today.getFullYear() % 100;
        const currentMonth = today.getMonth() + 1;
        return (year > currentYear || (year === currentYear && month >= currentMonth)) && month >= 1 && month <= 12;
      }),
    phone: Yup.string()
      .required(formatCommonMessage({ id: 'phoneRequired', defaultMessage: 'Phone Number is required.' }))
      .matches(
        /^[0-9]+$/,
        formatCommonMessage({ id: 'enterValidPhone', defaultMessage: 'Please enter a valid Phone Number.' }),
      )
      .min(10, formatCommonMessage({ id: 'phoneShouldBe', defaultMessage: 'Phone Number should be 10 to 14 digits.' }))
      .max(14, formatCommonMessage({ id: 'phoneShouldBe', defaultMessage: 'Phone Number should be 10 to 14 digits.' })),

    creditCardNumber: Yup.string()
      .required(
        formatCommonMessage({ id: 'creditCardNumberRequired', defaultMessage: 'Credit Card Number is required.' }),
      )
      .matches(
        /^(60194[45]\d{10}|4\d{12}(?:\d{3})?|5[1-5]\d{14}|2[2-7]\d{14}|3[47]\d{13}|6\d{15})$/,
        formatCommonMessage({
          id: 'enterValidCreditCardNumber',
          defaultMessage: 'Please try again. Enter the number exactly as it appears on your credit card.',
        }),
      ),
    securityCode: securityCodeValidation(detectedCardType),
  });

  const shippingAddressForm: any = {
    resolver: yupResolver(shippingAddressFormSchema),
    mode: 'onBlur',
  };

  const handleFormSubmit = (data) => {
    setNameOnCard(data?.nameOnCard);
    setCreditCardNumber(data?.creditCardNumber);
    setExpirationDate(data?.expirationDate);
    setSecurityCode(data?.securityCode);
    setFirstName(data?.firstName);
    setLastName(data?.lastName);
    setPhone(data?.phone);
    setPostalCode(data?.postalCode);
    setState(data?.state);
    setCity(data?.city);
    setStreetName(data?.streetName);
    setStreetNumber(data?.streetNumber);
    paymentSectionValidation();
  };

  const handleUseShippingAddress = (checked) => {
    setChecked(checked);
    reset();
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    trigger,
    setValue,
  } = useForm(shippingAddressForm);

  useEffect(() => {
    if (checked) {
      const itemShippingAddresses = loggedIn ? cartList?.itemShippingAddresses : getGuestShippingAddresses(cartList);
      if (itemShippingAddresses && itemShippingAddresses.length > 0) {
        const selectedShippingAddress = itemShippingAddresses[itemShippingAddresses.length - 1];
        if (selectedShippingAddress) {
          reset({
            firstName: selectedShippingAddress?.firstName,
            lastName: selectedShippingAddress?.lastName,
            streetName: selectedShippingAddress?.streetName,
            streetNumber: selectedShippingAddress?.streetNumber,
            city: selectedShippingAddress?.city,
            state: selectedShippingAddress?.state,
            postalCode: selectedShippingAddress?.postalCode,
            phone: selectedShippingAddress?.phone,
          });
        }
      }
    } else
      reset({
        firstName: '',
        lastName: '',
        streetName: '',
        streetNumber: '',
        city: '',
        state: '',
        postalCode: '',
        phone: '',
      });
  }, [checked]);

  const emailSchema = Yup.string()
    .required(formatCheckoutMessage({ id: 'emailRequired', defaultMessage: 'Email is required.' }))
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
      formatCheckoutMessage({ id: 'inValidEmail', defaultMessage: 'Invalid email format.' }),
    );
  const phoneSchema = Yup.string()
    .required(formatCommonMessage({ id: 'phoneRequired', defaultMessage: 'Phone Number is required.' }))
    .matches(
      /^(?:\+\d{10,15}|\d{10,15})$/,
      formatCommonMessage({ id: 'enterValidPhone', defaultMessage: 'Please enter a valid Phone Number.' }),
    );

  const handlePhoneChange = (phoneNumber) => {
    const phoneValue = phoneNumber;
    setReviewPhone(phoneValue);
    phoneSchema
      .validate(phoneValue)
      .then(() => {
        setPhoneError('');
      })
      .catch((error) => {
        setPhoneError(error.message);
      });
  };

  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    setReviewEmail(emailValue);
    emailSchema
      .validate(emailValue)
      .then(() => {
        setEmailError('');
      })
      .catch((error) => {
        setEmailError(error.message);
      });
  };
  const blockSpecialChar = (e) => {
    const key = e.key || String.fromCharCode(e.which || e.keyCode);
    return (
      (key >= 'A' && key <= 'Z') ||
      (key >= 'a' && key <= 'z') ||
      key === 'Backspace' ||
      key === 'Tab' ||
      key === 'Enter' ||
      key === ' ' ||
      (key >= '0' && key <= '9')
    );
  };

  const handleKeyPress = (event) => {
    if (!blockSpecialChar(event) && event.key !== '+') {
      event.preventDefault();
    }
  };

  const preventPaste = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    const hasGiftCard = cartList?.lineItems?.some((lineItem) =>
      PHYSICAL_GIFT_CARDS?.includes(lineItem?.variant?.attributes?.customProductType),
    );
    if (cartList?.lineItems?.some((lineItem) => lineItem?.isMilitaryClothing)) {
      setIsAllMilitaryItems(true);
    } else {
      setIsAllMilitaryItems(false);
    }
    setIsBillingSame(hasGiftCard);
  }, [cartList?.lineItems]);

  const continueToPayment = () => {
    setActiveShipment(true);
    setActivePayment(true);
    setShippingSectionError('');
    if (selectedCardDetails?.cardType.startsWith('Milstar')) {
      addCardForPayment(selectedCardValue);
    }
  };
  const { trackBeginCheckout } = useGATrackCheckoutAction();
  useEffect(() => {
    trackBeginCheckout({ cartList, account });
  }, []);
  useEffect(() => {
    const subscriptionProduct = cartList?.lineItems?.find((lineitem) => lineitem?.custom?.fields?.isSubscription);
    if (subscriptionProduct) {
      setIsAnyProductSubscribed(true);
    }
  }, [cartList]);

  useEffect(() => {
    blackBoxValueScript();
    const element = document.getElementById('ioBlackBox') as HTMLInputElement;
    setBlackBoxValue(element);
    if (blackBoxValue) {
      setBlackBox(blackBoxValue.value);
    }
  }, [continueToPayment]);

  const [itemInStock, setItemInStock] = useState(null);
  const [OOSConfirm, setOOSConfirm] = useState(false);
  useEffect(() => {
    getCartInventory()
      .then((res) => {
        Object.keys(res).map((item, index) => {
          setItemInStock(res);
          if (!res[item]?.availability?.isOnStock) {
            setOOSConfirm(true);
          } else {
            setOOSConfirm(false);
          }
        });
      })
      .catch((err) => console.log(err))
      .finally();
  }, []);
  const [cardNoError, setCardNoError] = useState('');

  const handleCreditCardSelection = () => {
    if (cardNoError) {
      setCardNoError('');
    }
  };
  const handleCreditCardClick = () => {
    setCreditCardActive(!creditCardActive);
  };

  useEffect(() => {
    if (studioConfig?.monetateConstants) {
      try {
        const monetateConfig = JSON?.parse(studioConfig?.monetateConstants?.replace(/'/g, '"'));
        setMonetateConfig(monetateConfig);
      } catch (error) {
        console.error('Errorparsing projectConfig:', error);
      }
    }
  }, [studioConfig]);

  useEffect(() => {
    const processMonetate = async () => {
      const callMonetate = async () => {
        setMonetateSent(true);
        await sendToMonetate(
          {
            page: 'checkout',
            cart: {
              ...cartList,
            },
          },
          monetateConfig,
        );
      };
      if (cartList && monetateConfig && !monetateSent) {
        await callMonetate();
        setCartProductDetailEventSent(true);
      }
    };

    const fetchData = async () => {
      await processMonetate();
    };
    fetchData();
  }, [cartList, monetateConfig]);

  return (
    <>
      <section
        className=" mx-auto w-full pt-7 xl:w-[1147px]"
        aria-labelledby="guest-checkout-section-heading"
        role="group"
      >
        <h2 id="guest-checkout-section-heading" className="mb-7 text-affes-2xl font-bold text-gray-900">
          {!loggedIn
            ? formatCheckoutMessage({ id: 'secureGuestCheckout', defaultMessage: 'Secure Guest Checkout' })
            : formatCheckoutMessage({ id: 'secureCheckout', defaultMessage: 'Secure Checkout' })}
        </h2>
        <section className="block lg:flex lg:justify-between">
          <main className="w-full lg:w-[620px] xl:w-[786px]">
            {activeShipment ? (
              <ShippingSection
                loggedIn={loggedIn}
                formatCheckoutMessage={formatCheckoutMessage}
                shippingSectionError={shippingSectionError}
                getSeparatedDeliverySections={getSeparatedDeliverySections}
                cartList={cartList}
                VIA_EMAIL_PRODUCTS={VIA_EMAIL_PRODUCTS}
                DONATION_ITEMS={DONATION_ITEMS}
                getAllCustomMessages={getAllCustomMessages}
                giftImg={giftImg}
                EGIFT_CUSTOM_PRODUCTS={EGIFT_CUSTOM_PRODUCTS}
                inventoryError={inventoryError}
                activePayment={activePayment}
                continueToPayment={continueToPayment}
                trackUserAction={trackUserAction}
                GA_CONTINUE_TO_PAYMENT={GA_CONTINUE_TO_PAYMENT}
                account={account}
              />
            ) : (
              <section
                className="border-[1px solid #EDEDED] mb-[15px] flex h-[44px] items-center justify-between rounded-sm border bg-[#EDEDED] px-[17px] xl:w-[776px]"
                role="group"
                aria-labelledby="shipping-pickup-heading"
              >
                <h4
                  id="shipping-pickup-heading"
                  className="flex items-center  py-3 text-affes-xl font-bold text-gray-900"
                >
                  <CheckShippingPickupIconActive className="mr-3" />
                  {formatCheckoutMessage({ id: 'shippingPickup', defaultMessage: 'Shipping & Pick Up' })}{' '}
                </h4>
                <button
                  type="button"
                  className="cursor-pointer"
                  onClick={editShippingPickup}
                  aria-label="Edit Sipping & Pick Up"
                >
                  <EditPencil />
                </button>
              </section>
            )}
            {activePayment ? (
              <PaymentSection
                formatCheckoutMessage={formatCheckoutMessage}
                paymentSectionError={paymentSectionError}
                genericError={genericError}
                loggedIn={loggedIn}
                dropDownActive={dropDownActive}
                setDropDownActive={setDropDownActive}
                cartList={cartList}
                setCardNoError={setCardNoError}
                cardNoError={cardNoError}
                projectConfiguration={projectConfiguration}
                creditCardActive={creditCardActive}
                fetchDefaultPayment={fetchDefaultPayment}
                reCalculatePayments={reCalculatePayments}
                isAnyProductSubscribed={isAnyProductSubscribed}
                showCardSelector={showCardSelector}
                handleCardChange={handleCardChange}
                dropDownChange={dropDownChange}
                showUseCardOption={showUseCardOption}
                selectedCardValue={selectedCardValue}
                allCards={allCards}
                cardDisplayNames={cardDisplayNames}
                setIsOpen={setIsOpen}
                addPaymentError={addPaymentError}
                cardError={cardError}
                selectedCardDetails={selectedCardDetails}
                isCardErrorMsg={isCardErrorMsg}
                setShowCardSelector={setShowCardSelector}
                handleCreditCardSelection={handleCreditCardSelection}
                handleCreditCardClick={handleCreditCardClick}
                setOpenPayYourWayModal={setOpenPayYourWayModal}
                payYourWayOptions={payYourWayOptions}
                installmentKeys={installmentKeys}
                payYourWaySelectedOption={payYourWaySelectedOption}
                handleRadioChange={handleRadioChange}
                billingAddressError={billingAddressError}
                getformattedAddress={getformattedAddress}
                addrError={addrError}
                activeOrderReview={activeOrderReview}
                paymentSectionValidation={paymentSectionValidation}
                trackUserAction={trackUserAction}
                GA_ORDER_REVIEW={GA_ORDER_REVIEW}
                account={account}
                isReviewButtonDisabled={isReviewButtonDisabled}
                isShowGuestPaymentSummary={isShowGuestPaymentSummary}
                setIsShowGuestPaymentSummary={setIsShowGuestPaymentSummary}
                detectedCardType={detectedCardType}
                paymentInfo={paymentInfo}
                formatCommonMessage={formatCommonMessage}
                register={register}
                errors={errors}
                setDetectedCardType={setDetectedCardType}
                IdentifyCardType={IdentifyCardType}
                expirationDate={expirationDate}
                setExpirationDate={setExpirationDate}
                setValue={setValue}
                trigger={trigger}
                isBillingSame={isBillingSame}
                checked={checked}
                handleUseShippingAddress={handleUseShippingAddress}
                handleKeyPress={handleKeyPress}
                preventPaste={preventPaste}
                firstName={firstName}
                lastName={lastName}
                streetName={streetName}
                streetNumber={streetNumber}
                city={city}
                stateOptions={stateOptions}
                postalCode={postalCode}
                phone={phone}
                handleSubmit={handleSubmit}
                handleFormSubmit={handleFormSubmit}
                milStarCardError={milStarCardError}
                setMilStarCardError={setMilStarCardError}
                getAllCustomMessages={getAllCustomMessages}
                cvv={cvv}
                setCvv={setCvv}
                checkBlockedCards={checkBlockedCards}
                blockedCards={blockedCards}
              />
            ) : (
              <>
                {activeOrderReview ? (
                  <section
                    aria-labelledby="order-review-section"
                    className="border-[1px solid #EDEDED] mb-[15px] flex h-[44px] items-center justify-between rounded-sm border bg-[#EDEDED] px-[17px] xl:w-[776px]"
                  >
                    <h4
                      id="order-review-section"
                      className="flex items-center  py-3 text-affes-xl font-bold text-gray-900"
                    >
                      <CheckShippingPickupIconActive className="mr-3" />
                      {formatCheckoutMessage({ id: 'payment', defaultMessage: 'Payment' })}{' '}
                    </h4>
                    <button
                      type="button"
                      className="cursor-pointer"
                      onClick={editPayment}
                      aria-label="Edit Button for Payment"
                    >
                      <EditPencil />
                    </button>
                  </section>
                ) : (
                  <section
                    aria-labelledby="payment-section"
                    className="border-[1px solid #EDEDED] mb-[15px] rounded-sm border xl:w-[776px]"
                  >
                    <h4
                      id="payment-section"
                      className="flex items-center bg-[#EDEDED] px-[17px] py-3 text-affes-xl font-bold text-gray-600"
                    >
                      <CheckShippingPickupIconDisabled className="mr-3" />
                      {formatCheckoutMessage({ id: 'payment', defaultMessage: 'Payment' })}{' '}
                    </h4>
                  </section>
                )}
              </>
            )}

            {activeOrderReview ? (
              <OrderReviewSection
                unexpectedError={unexpectedError}
                loggedIn={loggedIn}
                reviewEmail={reviewEmail}
                handleEmailChange={handleEmailChange}
                emailError={emailError}
                reviewPhone={reviewPhone}
                handlePhoneChange={handlePhoneChange}
                phoneError={phoneError}
                reviewOptinComm={reviewOptinComm}
                setReviewOptinComm={setReviewOptinComm}
                formatCheckoutMessage={formatCheckoutMessage}
              />
            ) : (
              <section
                aria-aria-labelledby="order-review-section-edit"
                className="border-[1px solid #EDEDED] mb-[15px] rounded-sm border"
              >
                <h4
                  id="order-review-section-edit"
                  className="flex items-center bg-[#EDEDED] px-[17px] py-3 text-affes-xl font-bold text-gray-600"
                >
                  <CheckShippingPickupIconDisabled className="mr-3" />
                  {formatCheckoutMessage({ id: 'orderReview', defaultMessage: 'Order Review' })}{' '}
                </h4>
              </section>
            )}
          </main>
          <aside className="w-full lg:w-[290px] xl:w-[330px]">
            <OrderSummary
              cart={cartList}
              onSubmit={onSubmit}
              isCheckout={true}
              cardType={selectedCardDetails?.cardType}
              isOrderConfirmed={isPlaceYourOrder}
              giftCardsApplied={giftCardsApplied}
              placeOrderClicked={placeOrder}
              setIsReviewButtonDisabled={setIsReviewButtonDisabled}
              itemQty={projectConfiguration?.productMaxQty}
              itemInStock={itemInStock}
              setProceedToCheckoutError={null}
              isCardErrorMsg={isCardErrorMsg}
              getAllCustomMessages={getAllCustomMessages}
              cvv={cvv}
              storeClosed={storeClosed}
            />
            {!account?.isMilStarCardHolder && <MilStarCardSection cardData={giftCard} />}
          </aside>
        </section>
      </section>

      {isOpen && (
        <AddNewCard
          showCardField={true}
          data={getCardType}
          closeModal={() => setIsOpen(false)}
          setSelectedCardData={setSelectedCardDetails}
          isCheckout={true}
          newCreditCardAddedToAccount={newCreditCardAddedToAccount}
        />
      )}
      {isEditBillingModalOpen && (
        <EditBillingAddressModal
          cart={cartList}
          closeEditBillingAddressModal={() => setIsEditBillingModalOpen(false)}
        ></EditBillingAddressModal>
      )}
      {privacyAct && <PrivayAct closeModal={() => setPrivacyAct(false)} privacyActModal={privacyAct} />}
      {siteterms && <SiteTermModal closeModal={() => setSiteTerms(false)} siteTermsModal={siteterms} />}
      {showLoader || loading || isCheckoutErrorLoading ? <Loader /> : null}
      {openPayYourWayModal && (
        <PayYourWay
          secureCheckoutModal={openPayYourWayModal}
          closeModal={() => {
            setOpenPayYourWayModal(false);
          }}
        />
      )}
    </>
  );
}

export default Checkout;
