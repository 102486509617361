import React, { useEffect, useState } from 'react';
import { useAccount, useCart } from 'frontastic';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { useFormat } from 'helpers/hooks/useFormat';
import CheckShippingPickupIconActive from '../../../../components/icons/checkShippingPickupActive';
import useGATrackCheckoutAction from 'helpers/hooks/gaEvents/useGATrackCheckoutAction';
import useGATrackAddActions from 'helpers/hooks/gaEvents/useGATrackAddActions';
import DisplayCustomMessage from './thankyouCustomMessage';
import { getSeparatedDeliverySections } from 'helpers/utils/getSeparatedDeliverySections';
import { priceInUSD } from 'helpers/utils/priceConverter';
import { trackEvent } from 'helpers/utils/gbTracker';

const OrderConfirmationThanks = () => {
  const { orderConfirmation, studioConfig } = useCart();
  const [valid, setValid] = useState(false);
  const [orderNumber, setOrderNumber] = useState(null);
  const router = useRouter();
  const { orderId } = router.query;
  const { trackAddPaymentInfo } = useGATrackAddActions();
  const { trackPurchase } = useGATrackCheckoutAction();
  const { account, loggedIn, setOrderConfirmationDetails } = useAccount();
  const cid = account?.cid;
  const [orderDate, setOrderDate] = useState<string>();
  const handleOrderConfirmation = async () => {
    try {
      const response = await orderConfirmation(orderId.toString(), true);
      if (response?.[0]?.displayLineItems?.length > 0) {
        const separatedItems = getSeparatedDeliverySections(response[0]?.displayLineItems) || [];
        const items = separatedItems?.map((item) => ({
          productId: item?.productKey,
          title: item?.name,
          price: priceInUSD(item?.price),
          quantity: item?.count,
          collection: item?.variant?.attributes?.customProductType,
          category: item?.productCategories,
          sku: `sku-${item?.variant?.sku}`,
        }));
        trackEvent(
          'order',
          {
            cart: {
              id: response[0]?.cartId,
              items: items,
            },
          },
          cid,
        );
      }
      setOrderConfirmationDetails(response);
      setOrderDate(response?.[0]?.createdAt);
      trackAddPaymentInfo({ cartList: response[0], account });
      trackPurchase({ cartList: response[0], orderId: response[0]?.orderId, account });
      setOrderNumber(response[0]?.orderId);
      const responseTyped = response as any;
      if (responseTyped.errorCode !== undefined || responseTyped.length == 0) {
        setValid(true);
      } else {
        setValid(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleOrderConfirmation();
  }, []);
  const { formatMessage: formatOrderConfirmationMessage } = useFormat({ name: 'orderConfirmation' });

  return (
    <>
      {valid ? (
        <section className=" mx-auto mb-5 w-full pt-12">
          <section>
            <p>{formatOrderConfirmationMessage({ id: 'thankyou', defaultMessage: 'Thank you!' })}</p>
            <p>{formatOrderConfirmationMessage({ id: 'invalidId', defaultMessage: 'Invalid Order ID.' })}</p>
            <section className="mt-5">
              <Link href="/">
                <button
                  type="submit"
                  name=""
                  className={`mx-auto h-[44px] w-[198px] rounded bg-blue-900 text-affes-xl font-bold text-white`}
                >
                  {formatOrderConfirmationMessage({ id: 'continueShopping', defaultMessage: 'Continue Shopping' })}
                </button>
              </Link>
            </section>
          </section>
        </section>
      ) : (
        <section className=" mx-auto mb-5 w-full pt-12">
          {orderNumber && (
            <section>
              <p className="mb-5 flex items-center text-affes-2xl font-bold text-gray-900">
                <CheckShippingPickupIconActive className="mr-1 mt-[6px] h-[24px] w-[24px]" />{' '}
                <span className="mt-[6px] text-xl">
                  {formatOrderConfirmationMessage({
                    id: 'thankyouGreeting',
                    defaultMessage: 'Thank you. Order {orderNumber} placed successfully.',
                    values: { orderNumber: orderNumber },
                  })}
                </span>
              </p>
              {loggedIn && <DisplayCustomMessage orderDate={orderDate} gracePeriod={studioConfig?.gracePeriod} />}
              <section className="mt-5">
                <Link href="/">
                  <button
                    type="submit"
                    name=""
                    className={`mx-auto h-[44px] w-full rounded bg-blue-900 text-affes-xl font-bold text-white md:w-[198px]`}
                  >
                    {formatOrderConfirmationMessage({ id: 'continueShopping', defaultMessage: 'Continue Shopping' })}
                  </button>
                </Link>
              </section>
            </section>
          )}
        </section>
      )}
    </>
  );
};

export default OrderConfirmationThanks;
